import './jq'
import './vendor/magnific-popup.min'
import './vendor/jquery.mask.min'

$(function() {
  const CALLBACK_URL = 'https://karagaeva.ru/landing/make_an_appointment/';
	const $menu_popup = $('.main-menu__popup');

	$(".main-menu__trigger").click(function(){
		$('body').addClass('body_pointer');
		$menu_popup.show(0);
		$menu_popup.animate(
			{right: parseInt($menu_popup.css('left'),10) === 0 ? -$menu_popup.outerWidth() : 0},
			300
		);
		return false;
	});

  const close = function(){
		$('body').removeClass('body_pointer');
		$menu_popup.animate(
			{right: parseInt($menu_popup.css('right'),10) === 0 ? -$menu_popup.outerWidth() : 0},
			300,
			function(){
				$menu_popup.hide(0);
			}
		);
		return false;
	}

	$(".main-menu__close").click(close);

  const links = $('.main-menu__link')
  const textColor = links[0].style.color
  links.click((e) => {
    window.location = e.target.href
    if (!window.matchMedia('(min-width: 1024px)').matches) {
      close()
    }
  })

  const observer = new IntersectionObserver((entries) => {
    const entry = entries.find((e) => e.isIntersecting)
    if (entry) {
      Array.from(links).forEach((link) => {
        const id = (new URL(link.href)).hash
        if (id === `#${entry.target.id}`) {
          link.style.color = 'black'
        } else {
          link.style.color = textColor
        }
      }, {
        threshold: [0.75]
      })
    }
  })
  Array.from(links).forEach((link) => {
    const id = (new URL(link.href)).hash
    const target = $(id)
    if (target) {
      observer.observe(target[0])
    }
  })

  $('.contacts__appointment').magnificPopup({
    type: 'inline',
    fixedContentPos: true,
    items: {
      src: '#callback-form'
    },
    callbacks: {
      open: function() {
        setTimeout(() => $('#tel').focus(), 100)
      },
    }
  });
  const form = $('.callback-form');
  form.submit(async (evt) => {
    evt.preventDefault()
    try {
      $.magnificPopup.close()
      $.magnificPopup.open({
        items: {
          src: '#loading'
        },
        fixedContentPos: true,
        type: 'inline'
      }, 0);
      await $.post(CALLBACK_URL, form.serialize())
      $.magnificPopup.close()
      setTimeout(() => {
        $.magnificPopup.open({
          items: {
            src: '#success'
          },
          fixedContentPos: true,
          type: 'inline'
        }, 0);
      }, 0)
    } catch (e) {
      console.error(e)
      $.magnificPopup.open({
        items: {
            src: '#fail'
        },
        fixedContentPos: true,
        type: 'inline'
      }, 0);
    }
  })

  $("#tel").mask("+7(999) 999-99-99")
});
